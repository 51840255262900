'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:UnitContactsCtrl

 # @description

###
class IncidentPriorityDispatchUnitTypesCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    IncidentPrioritiesManager
    $q
  ) ->
    @progress = true
    @model = {}

    init = =>
      @id = id
      @fields = IncidentPrioritiesManager.getDispatchUnitTypeForm()

      @load = load
      @add = add
      @remove = remove
      @cancel = cancel
      @submit = submit

      @load()
    ##########

    add = ->
      @progress = true
      $q.when(IncidentPrioritiesManager.addDispatchUnitType(@id, @model))
        .then =>
          @load()

    remove = (contactId) ->
      @progress = true
      $q.when(IncidentPrioritiesManager.removeDispatchUnitType(@id, contactId))
        .then =>
          @load()

    load = ->
      console.log 'load'
      IncidentPrioritiesManager.getDispatchUnitTypes(@id)
        .then (data) =>
          @items = data
          @progress = false
          @form = {}


    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'


    init()
angular
  .module('mundoAdmin.incidents')
  .controller 'IncidentPriorityDispatchUnitTypesCtrl', IncidentPriorityDispatchUnitTypesCtrl
